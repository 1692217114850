import { ChangeDetectionStrategy, Component, HostBinding, inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import '@diekeure/dcr-components/dcr-app-shell.js';

interface ShellConfig {
  header?: boolean;
  navigationRail?: boolean;
  navigationDrawer?: boolean;
  pageHeader?: boolean;
  pageFooter?: boolean;
  sideSheet?: boolean;
  footer?: boolean;
}

@Component({
  selector: 'campus-app-shell',
  templateUrl: './app-shell.component.html',
  styleUrls: ['./app-shell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppShellComponent implements OnInit, OnDestroy {
  private _router = inject(Router);
  private _route = inject(ActivatedRoute);
  private _destroy$ = new Subject<void>();

  public config: ShellConfig = {};

  @HostBinding('class') readonly defaultClasses = ['block', 'w-full', 'h-full'];

  ngOnInit(): void {
    this.config = this._getInitialConfig();
    this._setConfigForRoute(this._route);
    this._listenToRouteChanges();
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  public skipToContent(): void {
    document.querySelector('main').focus();
  }

  private _listenToRouteChanges(): void {
    this._router.events
      .pipe(
        filter((event): event is NavigationEnd => event instanceof NavigationEnd),
        takeUntil(this._destroy$)
      )
      .subscribe(() => {
        document.querySelector('main')?.scrollTo(0, 0);
        this.config = this._getInitialConfig();
        this._setConfigForRoute(this._route);
      });
  }

  private _setConfigForRoute(route: ActivatedRoute): void {
    if (route.children.length === 0) return;

    for (const child of route.children) {
      this._setByRouteOutlet(child);
      this._setByRouteData(child);
      this._setConfigForRoute(child);
    }
  }

  private _setByRouteOutlet(route: ActivatedRoute): void {
    if (route.outlet !== 'primary') {
      const key = this._getConfigKeyFromOutlet(route.outlet);
      if (key) this.config[key] = this.config[key] === undefined || this.config[key];
    }
  }

  private _setByRouteData(route: ActivatedRoute): void {
    const { data } = route.snapshot;

    for (const [key, value] of Object.entries(data)) {
      if (key in this.config && typeof value === 'boolean') {
        this.config[key as keyof ShellConfig] = value;
      }
    }
  }

  private _getInitialConfig() {
    return {
      header: undefined,
      navigationRail: undefined,
      navigationDrawer: undefined,
      pageHeader: undefined,
      pageFooter: undefined,
      sideSheet: undefined,
      footer: undefined,
    };
  }

  private _getConfigKeyFromOutlet(outlet: string): keyof ShellConfig | null {
    const mapping: Record<string, keyof ShellConfig> = {
      header: 'header',
      'navigation-rail': 'navigationRail',
      'navigation-drawer': 'navigationDrawer',
      'page-header': 'pageHeader',
      'page-footer': 'pageFooter',
      'side-sheet': 'sideSheet',
      footer: 'footer',
    };
    return mapping[outlet] || null;
  }
}
