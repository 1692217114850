import { inject, Injectable, OnDestroy } from '@angular/core';
import { MaintenanceService } from '@campus/browser';
import {
  AUTH_SERVICE,
  AuthFacade,
  AuthServiceInterface,
  CampaignInterface,
  CampaignService,
  LinkedPersonService,
  RegistrationCredentials,
} from '@campus/public-portal';
import { merge, Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { RegisterTeacherInterface } from './components/register-teacher-form/register-teacher-form.component';

@Injectable({ providedIn: 'root' })
export class RegisterPageContainerViewModel implements OnDestroy {
  private authService: AuthServiceInterface = inject(AUTH_SERVICE);
  private auth = inject(AuthFacade);
  private maintenanceService = inject(MaintenanceService);
  private linkedPersonService = inject(LinkedPersonService);
  private campaignService = inject(CampaignService);
  private _registrationErrors = new Subject<string>();

  public registrationError$ = this.getRegistrationError$();
  public isMaintenanceMode$: Observable<boolean>;

  constructor() {
    this.isMaintenanceMode$ = this.maintenanceService.isMaintenanceMode();
  }

  public register(credentials: RegistrationCredentials) {
    const credentialsWithType = { ...credentials, userType: 'student' };
    this.auth.register(credentialsWithType);
  }

  public registerTeacher(teacher: RegisterTeacherInterface, campaign: string): Observable<string> {
    return this.linkedPersonService
      .createTeacherForCampaign(campaign, {
        email: teacher.email,
        firstName: teacher.firstName,
        name: teacher.lastName,
        password: teacher.password,
      })
      .pipe(
        tap(
          () => {},
          (errorResponse) => {
            const getError = (error) => {
              if (!error) return '';
              if (error.message) return error.message;
              return getError(error.error);
            };

            this._registrationErrors.next(getError(errorResponse));
          }
        )
      );
  }

  public getCampaign(campaignKey: string): Observable<CampaignInterface> {
    return this.campaignService.getCampaign(campaignKey);
  }

  private getRegistrationError$(): Observable<string> {
    return merge(this._registrationErrors.asObservable(), this.auth.error$);
  }

  ngOnDestroy(): void {
    this.authService.clearError();
  }
}
