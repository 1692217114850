import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostBinding,
  inject,
  Input,
  NgZone,
  OnDestroy,
  Output,
  QueryList,
  ViewChild,
} from '@angular/core';
import { defer, merge, Observable, Subject } from 'rxjs';
import { startWith, switchMap, take, takeUntil } from 'rxjs/operators';
import { isSelectableKey } from '../../core';
import { InputBooleanAttribute, isTruthyInput } from '../../core/empty-attribute';
import { HighlightableOption } from '../../core/navigation/activedescendant-list.controller';
import { NavigableOption } from '../../core/navigation/navigable-list.controller';
import { TypeaheadItem } from '../../core/typeahead';
import { MenuItemInterface, MenuItemSelectionChange } from '../../menu/menu-item/menu-item.component';
import { CDS_MENU_ITEM_PARENT } from '../../menu/menu.component';
import { SelectOptionComponent } from '../select-option/select-option.component';
import { SelectComponent } from '../select.component';

export class OptionGroupSelectionChange<T = any> {
  constructor(public source: OptionGroupComponent<T>, public isUserInput = false) {}
}

@Component({
  selector: 'campus-option-group',
  templateUrl: './option-group.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: CDS_MENU_ITEM_PARENT, useExisting: forwardRef(() => SelectComponent) }],
})
export class OptionGroupComponent<T = any>
  implements AfterViewInit, OnDestroy, HighlightableOption, NavigableOption, TypeaheadItem
{
  @ContentChildren(forwardRef(() => SelectOptionComponent), { descendants: true }) items: QueryList<MenuItemInterface>;

  @ViewChild('container', { static: true }) containerRef: ElementRef;

  //#region Private properties
  private _parent = inject(CDS_MENU_ITEM_PARENT);
  private _cd = inject(ChangeDetectorRef);
  private _ngZone = inject(NgZone);
  private _destroyed$ = new Subject<void>();
  private _selected = false;
  private _disabled: InputBooleanAttribute;
  element = inject(ElementRef).nativeElement;

  private readonly itemsSelectionChanges: Observable<MenuItemSelectionChange> = defer(() => {
    const items = this.items;

    if (items) {
      return items.changes.pipe(
        startWith(items),
        switchMap(() => merge(...items.map((option) => option.selectionChange)))
      );
    }

    return this._ngZone.onStable.pipe(
      take(1),
      switchMap(() => this.itemsSelectionChanges)
    );
  }) as Observable<MenuItemSelectionChange>;
  //#endregion

  //#region Public properties
  active = false;
  allSelected = false;
  someSelected = false;

  @HostBinding('class')
  defaultClasses = ['option-group', 'block'];

  @HostBinding('attr.role')
  role = 'group';

  tabIndex = -1;

  @Input() leadingIcon: string;

  @Input() label: string;
  @Input() overline: string;
  @Input() supportingText: string;
  @Input() trailingSupportingText: string;

  @Input() checkbox: InputBooleanAttribute;

  @HostBinding('attr.disabled')
  @HostBinding('attr.aria-disabled')
  @HostBinding('class.pointer-event-none')
  @HostBinding('class.cursor-default')
  @Input()
  get disabled() {
    return this._disabled === true ? true : undefined;
  }
  set disabled(value: InputBooleanAttribute) {
    this._disabled = isTruthyInput(value);
  }

  get multiple(): boolean {
    return isTruthyInput(this._parent.multiple);
  }
  get selected() {
    return this._selected;
  }

  get hasCheckbox(): boolean {
    return this.checkbox == null ? isTruthyInput(this._parent.checkbox) : isTruthyInput(this.checkbox);
  }

  get typeaheadText() {
    return this.label?.toLowerCase() || this.supportingText?.toLowerCase() || this.overline?.toLowerCase();
  }

  //#endregion

  //#region Outputs
  @Output() selectionChange = new EventEmitter<OptionGroupSelectionChange<T>>();
  //#endregion

  //#region Lifecycle hooks

  ngAfterViewInit(): void {
    this.items.changes.pipe(startWith(null), takeUntil(this._destroyed$)).subscribe(() => {
      this._resetOptions();
    });
    this._updateValue();
    if (this.disabled) {
      this._disableChanged();
    }
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }
  //#endregion

  //#region Public methods

  onGroupClicked(event: MouseEvent) {
    if (!this.multiple) return;

    event.preventDefault();

    if (this.disabled) return;

    this._toggleSelection();
  }

  onGroupKeydown(event: KeyboardEvent) {
    if (!this.multiple) return;

    if (this.disabled) return;

    if (isSelectableKey(event.code)) {
      event.preventDefault();
      this._toggleSelection();
    }
  }

  focus() {
    this.containerRef.nativeElement.focus();
  }

  setActive(): void {
    if (!this.active) {
      this.active = true;
      this.tabIndex = 0;
      this.focus();
      this._cd.markForCheck();
    }
  }
  setInactive(): void {
    if (this.active) {
      this.active = false;
      this.tabIndex = -1;
      this._cd.markForCheck();
    }
  }
  //#endregion

  //#region Private methods
  private _toggleSelection() {
    if (!this.allSelected) {
      this._selected = true;
    } else {
      this._selected = !this._selected;
    }

    this._cd.markForCheck();
    this._emitSelectionChangeEvent(true);
  }

  private _resetOptions() {
    const changedOrDestroyed = merge(this.items.changes, this._destroyed$);

    this.itemsSelectionChanges.pipe(takeUntil(changedOrDestroyed)).subscribe((event) => {
      this._updateValue();
    });
  }

  private _updateValue() {
    const items = this.items.toArray().filter((item) => !item.disabled);
    this.someSelected = items.some((item) => item.selected);
    this.allSelected = items.every((item) => item.selected);

    this._cd.markForCheck();
  }

  private _disableChanged() {
    if (this.items && this.items.length) {
      this.items.forEach((item) => (item.disabled = this.disabled));
    }
  }

  private _emitSelectionChangeEvent(isUserInput = false): void {
    this.selectionChange.emit(new OptionGroupSelectionChange<T>(this, isUserInput));
  }
  //#endregion
}
