import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  inject,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { fromEvent, Subject, timer } from 'rxjs';
import { filter, switchMap, takeUntil, tap } from 'rxjs/operators';
import { InputBooleanAttribute, isTruthyInput } from '../core/empty-attribute';
import { SurfacePositionController } from '../surface/surface-position.controller';
import { TooltipService } from './tooltip.service';

@Component({
  selector: 'campus-core-tooltip',
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TooltipCoreComponent implements OnChanges, AfterViewInit, OnDestroy {
  @ViewChild('surface', { static: true }) private surface: ElementRef;

  private element = inject(ElementRef).nativeElement;
  private _cd = inject(ChangeDetectorRef);
  private _destroyed$ = new Subject<void>();
  private _tooltipService = inject(TooltipService);

  @Input() tooltipPosition: 'popover' | 'fixed' | 'document' | 'absolute' = 'popover';
  @Input() tooltipXOffset = 0;
  @Input() tooltipYOffset = 4;
  @Input() tooltipDelay = 1500;
  @Input() tooltipWhenTruncated: InputBooleanAttribute;
  @Input() tooltipDisabled = false;

  @Input() anchor: HTMLElement;

  visible = false;

  private tooltipPositionController: SurfacePositionController;
  private _hover = false;

  get truncated() {
    const { offsetWidth, scrollWidth, offsetHeight, scrollHeight } = this.element;
    return offsetWidth < scrollWidth || offsetHeight < scrollHeight;
  }

  private _onOpened() {
    this.visible = true;
  }
  private _beforeClose() {}
  private _onClosed() {
    this.visible = false;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.tooltipWhenTruncated) {
      this.tooltipWhenTruncated = isTruthyInput(this.tooltipWhenTruncated);
    }
  }

  ngAfterViewInit(): void {
    this.tooltipPositionController = new SurfacePositionController(() => {
      return {
        anchorCorner: 'start-start',
        surfaceCorner: 'end-start',
        surfaceEl: this.surface.nativeElement,
        anchorEl: this.anchor || this.element,
        positioning: this.tooltipPosition === 'popover' ? 'document' : this.tooltipPosition,
        isOpen: this.visible,
        xOffset: this.tooltipXOffset,
        yOffset: this.tooltipYOffset,
        repositionStrategy: 'move',
        onOpen: this._onOpened.bind(this),
        beforeClose: this._beforeClose.bind(this),
        onClose: this._onClosed.bind(this),
      };
    });

    const mouseEnter = this.anchor ? fromEvent(this.anchor, 'mouseenter') : fromEvent(this.element, 'mouseenter');
    const mouseLeave = this.anchor ? fromEvent(this.anchor, 'mouseleave') : fromEvent(this.element, 'mouseleave');
    mouseEnter
      .pipe(
        filter(() => !this.tooltipDisabled),
        takeUntil(this._destroyed$)
      )
      .subscribe(() => {
        this._hover = true;
        if (this.tooltipWhenTruncated && !this.truncated) {
          return;
        }
        this._tooltipService.show(this);
        this.visible = true;
        this.tooltipPositionController.update();
        this._cd.markForCheck();
      });

    mouseLeave
      .pipe(
        takeUntil(this._destroyed$),
        tap((_) => (this._hover = false)),
        switchMap(() => timer(this.tooltipDelay))
      )
      .subscribe(() => {
        if (this._hover || (this.tooltipWhenTruncated && !this.truncated)) {
          return;
        }
        this.visible = false;
        this.tooltipPositionController.update();
        this._cd.markForCheck();
      });

    this._tooltipService.visibilityChange.pipe(takeUntil(this._destroyed$)).subscribe((tooltip) => {
      if (tooltip !== this) {
        this.visible = false;
        this.tooltipPositionController.update();
        this._cd.markForCheck();
      }
    });
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }
}
