import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { AbstractControl, FormBuilder, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { PORTAL_ENVIRONMENT_TOKEN } from '@campus/public-portal';

export interface RegisterTeacherInterface {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
}

export const passwordMatchedValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  const password = control.get('password');
  const passwordCheck = control.get('passwordCheck');

  return password && passwordCheck && password.value !== passwordCheck.value ? { passwordMatch: true } : null;
};

@Component({
  selector: 'polpo-register-teacher-form',
  templateUrl: './register-teacher-form.component.html',
})
export class RegisterTeacherFormComponent {
  private formBuilder = inject(FormBuilder);
  public portalEnvironment = inject(PORTAL_ENVIRONMENT_TOKEN);

  public pwdInputType = 'password';

  @Input() error: string;
  @Output() register = new EventEmitter<RegisterTeacherInterface>();

  public registerForm = this.formBuilder.group(
    {
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(12), Validators.maxLength(128)]],
      passwordCheck: ['', [Validators.required, Validators.minLength(12), Validators.maxLength(128)]],
    },
    { validators: passwordMatchedValidator }
  );

  public togglePwdVisibility() {
    this.pwdInputType = this.pwdInputType === 'password' ? 'text' : 'password';
  }

  public onSubmit() {
    this.register.emit(this.registerForm.value as RegisterTeacherInterface);
  }
}
