import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  inject,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { HighlightableOption, NavigableOption, SelectableOption } from '../../core';
import { InputBooleanAttribute, isTruthyInput } from '../../core/empty-attribute';
import { CDS_TABLE_ROW_PARENT, TableComponent } from '../table.component';

export class RowSelectionChange<T> {
  constructor(public source: RowInterface<T>, public isUserInput = false) {}
}

export interface RowInterface<T = any> extends HighlightableOption, NavigableOption, SelectableOption {
  selectionChange: EventEmitter<RowSelectionChange<T>>;
  value: T;
  state?: 'error' | 'success' | 'warning';
}

@Component({
  selector: 'campus-table-v2-row, [table-row]',
  templateUrl: './row.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: CDS_TABLE_ROW_PARENT, useExisting: TableComponent }],
})
export class TableRowComponent<T = any> implements RowInterface<T>, OnChanges {
  private _cd = inject(ChangeDetectorRef);
  protected _parent = inject(CDS_TABLE_ROW_PARENT);
  element = inject(ElementRef).nativeElement;

  //#region Private properties
  private _selected = false;
  //#endregion

  //#region Inputs
  @Input()
  selectable: InputBooleanAttribute;
  @Input()
  value: T;

  @Input() elevateCheckbox: boolean;

  @HostBinding('class.primary-container')
  @HostBinding('attr.aria-selected')
  @HostBinding('class.selected')
  @Input()
  get selected(): boolean {
    return this._selected;
  }
  set selected(value: InputBooleanAttribute) {
    this._selected = isTruthyInput(value);
  }
  @Input() state?: 'error' | 'success' | 'warning';
  @Input() loading: boolean;
  //#endregion

  //#region Public properties
  get multiple(): boolean {
    return isTruthyInput(this._parent.multiple);
  }

  get readonly(): boolean {
    return isTruthyInput(this._parent.readonly);
  }

  disabled: InputBooleanAttribute;
  @HostBinding('class')
  rowColor: string;

  //#endregion

  //#region Outputs
  @Output() selectionChange = new EventEmitter<RowSelectionChange<T>>();
  //#endregion

  //#region Lifecycle Hooks
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectable) {
      this.selectable = isTruthyInput(changes.selectable.currentValue);
    }
    if (changes.state) {
      switch (changes.state.currentValue) {
        case 'error':
          this.rowColor = 'error-container';
          break;
        case 'success':
          this.rowColor = 'success-container';
          break;
        case 'warning':
          this.rowColor = 'warn-container';
          break;
        default:
          this.rowColor = '';
          break;
      }

      this._cd.markForCheck();
    }
  }
  //#endregion

  //#region Public methods

  setActive(): void {
    // Implement setActive logic here
    console.log('Row is set active');
  }

  setInactive(): void {
    // Implement setInactive logic here
    console.log('Row is set inactive');
  }

  select(emitEvent?: boolean): void {
    if (!this._selected) {
      this._selected = true;
      this._cd.markForCheck();

      if (emitEvent) {
        this._emitSelectionChangeEvent();
      }
    }
  }

  deselect(emitEvent?: boolean): void {
    if (this._selected) {
      this._selected = false;
      this._cd.markForCheck();
    }

    if (emitEvent) {
      this._emitSelectionChangeEvent();
    }
  }

  selectViaInteraction(): void {
    if (!this.disabled) {
      this._selected = this.multiple ? !this._selected : true;
      this._cd.markForCheck();
      this._emitSelectionChangeEvent(true);
    }
  }

  onCheck(checked: boolean) {
    if (!this.disabled) {
      if (checked) {
        this.select(true);
      } else {
        this.deselect(true);
      }
    }
  }

  //#endregion

  //#region Private methods
  private _emitSelectionChangeEvent(isUserInput = false): void {
    this.selectionChange.emit(new RowSelectionChange(this, isUserInput));
  }
  //#endregion
}
