<span [attr.data-target]="target" class="[ relative corner-full pointer-event-none ]">
  <span
    [ngClass]="[
      'switch-track',
      '[ relative flex items-center corner-full ]',
      '[ bg-surface-container-highest border border-2 ]',
      currentValue && !disabled ? 'border-transparent' : 'border-stroke',
      disabled ? '[ border-opacity-disabled bg-opacity-disabled ]' : '[ bg-opacity-full ]',
      '[ index-content ]'
    ]"
  >
    <div
      [ngClass]="[
        'switch-container',
        '[ absolute inset-0  ]',
        '[ corner-inherit ]',
        '[ reduce-motion:transition-none ]',
        !disabled ? '[ bg-primary bg-opacity-full ]' : '[ bg-on-surface bg-opacity-dragged ]'
      ]"
      [class.transition-opacity]="viewInitialized"
      [class.bg-opacity-none]="!currentValue"
    ></div>

    <campus-touch-target [class.hidden]="target === 'none'"></campus-touch-target>

    <div
      [ngClass]="[
        'switch-handle',
        '[ absolute top-1/2 left-0 ]',
        '[ flex items-center justify-center ]',
        '[ corner-full ]',
        '[ transform -translate-y-1/2  origin-center reduce-motion:scale-100 reduce-motion:group-hover:scale-100  ]',
        currentValue ? 'rotate-0' : '-rotate-90',
        pressing ? 'scale-125' : 'group-hover:scale-110'
      ]"
      [class.transition-transform]="viewInitialized"
      [class.bg-stroke]="!currentValue || disabled"
      [class.elevation-0]="!currentValue || disabled"
      [class.bg-opacity-disabled]="disabled"
      [class.bg-on-primary]="currentValue && !disabled"
      [class.elevation-1]="currentValue && !disabled"
    >
      <campus-state-layer [for]="element" type="circular" class="bg-primary"></campus-state-layer>

      <ng-container *ngIf="withIcons || showOnlySelectedIcon">
        <ng-container
          *ngTemplateOutlet="currentValue ? activeIcon : showOnlySelectedIcon ? '' : inactiveIcon"
        ></ng-container>
      </ng-container>
    </div>
  </span>

  <campus-focus-ring [for]="element"></campus-focus-ring>
</span>

<div *ngIf="label || supportingText">
  <div *ngIf="label" class="[ label-medium on-surface ]" [class.text-opacity-disabled]="disabled">{{ label }}</div>
  <div *ngIf="supportingText" class="[ label-small on-surface-variant ]" [class.text-opacity-disabled]="disabled">{{
    supportingText
  }}</div>
</div>

<ng-template #activeIcon>
  <campus-icon
    *ngIf="selectedIcon; else activeDefault"
    [svgIcon]="selectedIcon"
    class="switch-handle-icon"
    [ngClass]="[disabled ? 'icon-surface-container-highest' : 'icon-primary']"
  ></campus-icon>
  <ng-template #activeDefault>
    <svg
      viewBox="0 0 16 16"
      width="16"
      height="16"
      class="switch-handle-icon"
      [ngClass]="[disabled ? 'fill-surface-container-highest' : 'fill-primary']"
    >
      <path
        d="M5.86333 10.5833L3.08333 7.80333L2.13667 8.74333L5.86333 12.47L13.8633 4.47L12.9233 3.53L5.86333 10.5833Z"
      />
    </svg>
  </ng-template>
</ng-template>
<ng-template #inactiveIcon>
  <campus-icon
    *ngIf="unselectedIcon; else inactiveDefault"
    [svgIcon]="unselectedIcon"
    class="switch-handle-icon [ icon-surface-container-highest ]"
  ></campus-icon>
  <ng-template #inactiveDefault>
    <svg viewBox="0 0 16 16" width="16" height="16" class="switch-handle-icon [ fill-surface-container-highest ]">
      <path
        d="M12.6667 4.27334L11.7267 3.33334L8 7.06001L4.27333 3.33334L3.33333 4.27334L7.06 8.00001L3.33333 11.7267L4.27333 12.6667L8 8.94001L11.7267 12.6667L12.6667 11.7267L8.94 8.00001L12.6667 4.27334Z"
      />
    </svg>
  </ng-template>
</ng-template>
