import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IframeComponent } from './iframe/iframe.component';
import { NoHtmlPipe } from './noHtml/noHtml.pipe';
import { SafeHtmlPipe } from './safeHtml/safeHtml.pipe';
import { StorageService } from './storage/storage.service';
import { BROWSER_STORAGE_SERVICE_TOKEN } from './storage/storage.service.interface';

@NgModule({
  imports: [CommonModule],
  providers: [{ provide: BROWSER_STORAGE_SERVICE_TOKEN, useClass: StorageService }],
  exports: [IframeComponent, SafeHtmlPipe, NoHtmlPipe],
  declarations: [IframeComponent, SafeHtmlPipe, NoHtmlPipe],
})
export class BrowserModule {}
