import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  inject,
  Input,
  Output,
} from '@angular/core';
import { InputBooleanAttribute, isTruthyInput } from '../../core/empty-attribute';
import { Ripple } from '../../ripple/ripple.decorator';

@Component({ template: '', changeDetection: ChangeDetectionStrategy.OnPush })
@Ripple()
export class ButtonCoreComponent {
  elementRef = inject(ElementRef);
  element = inject(ElementRef).nativeElement;

  //#region private fields
  protected _selected: boolean;
  private _disabled: boolean;

  protected coreClasses = [
    'relative',

    'items-center',
    'justify-center',

    'outline-none',
    'select-none',
    'decoration-none',
    'cursor-pointer',
    'index-surface',

    'group',
    'state-layer-group',
  ];

  @HostBinding('attr.role')
  buttonRole = 'button';

  @HostBinding('attr.data-checked')
  @HostBinding('class.selected')
  @Input()
  set selected(value: InputBooleanAttribute) {
    if (value !== this._selected) {
      this._selected = isTruthyInput(value);
      this.selectedChange.emit(this._selected);
    }
  }

  get selected(): boolean {
    return this._selected;
  }

  @HostBinding('attr.aria-disabled')
  @HostBinding('attr.disabled')
  @HostBinding('class.pointer-event-none')
  @HostBinding('class.cursor-default')
  @Input()
  get disabled() {
    return this._disabled === true ? true : undefined; //removed the disabled attribute for false values
  }
  set disabled(value: InputBooleanAttribute) {
    this._disabled = isTruthyInput(value);
  }

  @HostBinding('attr.aria-label')
  @Input()
  ariaLabel: string;

  @HostBinding('attr.aria-labelledby')
  @Input()
  ariaLabelledby: string;

  @Input() ripple: boolean;

  @Input() badge: string | number;

  @Input() plainTooltip: string;

  @Output() selectedChange = new EventEmitter<boolean>();
}
