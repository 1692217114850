<td
  class="[ relative sticky left-0 index-sticky-container ][ p-i-s p-b-xs ][ text-center ][ column-w-m ]"
  [ngClass]="selected ? 'primary-container' : 'surface'"
  *ngIf="selectable"
>
  <campus-checkbox
    [checked]="selected"
    (valueChange)="onCheck($event)"
    [disabled]="loading"
    data-cy="checkbox-table-tbody-row"
  ></campus-checkbox>
  <campus-state-layer *ngIf="!readonly" [for]="element" class="[ bg-primary ]"></campus-state-layer>

  <span
    class="[ absolute inset-0 elevation-0 index-pullback ][ transition-elevation ]"
    [class.elevation-1]="elevateCheckbox"
  ></span>
</td>

<ng-content></ng-content>
