import { LayoutModule } from '@angular/cdk/layout';
import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { CdkTreeModule } from '@angular/cdk/tree';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { DateAdapter, MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyPaginatorModule as MatPaginatorModule } from '@angular/material/legacy-paginator';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { MatLegacySliderModule as MatSliderModule } from '@angular/material/legacy-slider';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSortModule } from '@angular/material/sort';
import { RouterModule } from '@angular/router';
import { ComponentsModule } from '@campus/components';
import { EnvironmentModule } from '@campus/environment';

import { SvgModule } from '@campus/svg';
import { BeDateAdapter, UtilsModule } from '@campus/utils';
import { LottieModule } from 'ngx-lottie';
import { AbilityMeterComponent } from './ability-meter/ability-meter.component';

import { ParticlesDirective } from './animations';
import { AppBarComponent } from './app-bar/app-bar.component';
import { BannerComponent } from './banner/banner.component';
import {
  BottomNavigationComponent,
  BottomNavigationLogoDirective,
} from './bottom-navigation/bottom-navigation.component';
import { BreadcrumbsTemplateComponent } from './breadcrumbs';
import { BreadcrumbsComponent, BreadcrumbsSeparatorDirective } from './breadcrumbs/breadcrumbs.component';
import { ButtonComponent } from './button/button.component';
import { BorderDirective } from './button/directives/button-border.directive';
import { CircleDirective } from './button/directives/button-circle.directive';
import { DangerDirective } from './button/directives/button-danger.directive';
import { DenseDirective } from './button/directives/button-dense.directive';
import { DisabledDirective } from './button/directives/button-disabled.directive';
import { FabDirective } from './button/directives/button-fab.directive';
import { FlatDirective } from './button/directives/button-flat.directive';
import { IconDirective } from './button/directives/button-icon.directive';
import { InlineDirective } from './button/directives/button-inline.directive';
import { LargeDirective } from './button/directives/button-large.directive';
import { PrimaryDirective } from './button/directives/button-primary.directive';
import { RoundedCornersDirective } from './button/directives/button-rounded-corners.directive';
import { WarningDirective } from './button/directives/button-warning.directive';
import { CappedChipComponent } from './capped-chip/capped-chip.component';
import {
  CardActionsDirective,
  CardAvatarDirective,
  CardBadgeDirective,
  CardComponent,
  CardContentDirective,
  CardFooterDirective,
  CardHeaderActionsDirective,
  CardImageDirective,
  CardOverlineDirective,
  CardSelectedIconDirective,
  CardSubTitleDirective,
  CardTitleDirective,
} from './card/card.component';
import { ChartElementComponent } from './charts';
import { ActivityGraphComponent } from './charts/activity-graph/activity-graph.component';
import { BarChartComponent } from './charts/bar-chart/bar-chart.component';
import { GridDiagramComponent } from './charts/grid-diagram/grid-diagram.component';
import { CollapsibleContainerComponent } from './collapsible-container/collapsible-container.component';

import {
  ConceptCardActionDirective,
  ConceptCardComponent,
  ConceptCardImageDirective,
  ConceptCardSubtitleDirective,
  ConceptCardTitleDirective,
} from './concept-card/concept-card.component';
import {
  ConfirmDeleteComponent,
  ConfirmDeleteContentDirective,
  ConfirmDeleteTitleDirective,
} from './confirm-delete/confirm-delete.component';
import { ConfirmableSelectComponent } from './confirmable-select/confirmable-select.component';
import { ConfirmationModalComponent } from './confirmation-modal/confirmation-modal.component';
import { ContentEditableComponent } from './content-editable/content-editable.component';
import { ContentPreviewComponent } from './content-preview/content-preview.component';
import { ContentThumbnailComponent } from './content-thumbnail/content-thumbnail.component';
import { DateRangePickerComponent } from './date-range-picker/date-range-picker.component';
import { DiaboloPhaseComponent } from './diabolo-phase/diabolo-phase.component';
import { DropAreaComponent } from './drop-area/drop-area.component';
import { DropdownMenuItemComponent } from './dropdown-menu-item/dropdown-menu-item.component';
import { DropdownMenuComponent } from './dropdown-menu/dropdown-menu.component';
import { EditableInlineTagListComponent } from './editable-inline-tag-list/editable-inline-tag-list.component';
import { EmptyStateComponent } from './empty-state/empty-state.component';
import { ExpansionPanelContentDirective } from './expansion-panel/expansion-panel-content.directive';
import { ExpansionPanelHeaderIconDirective } from './expansion-panel/expansion-panel-header-icon.directive';
import { ExpansionPanelHeaderDirective } from './expansion-panel/expansion-panel-header.directive';
import { ExpansionPanelComponent } from './expansion-panel/expansion-panel.component';
import { ExpansionPanelsComponent } from './expansion-panel/expansion-panels.component';
import { FileExtensionComponent } from './file-extension/file-extension.component';
import { FileIconComponent } from './file-icon/file-icon.component';
import { FilterTextInputComponent } from './filter-text-input/filter-text-input.component';
import { FolderDetailsDirective } from './folder/directives/folder-details.directive';
import { FolderComponent } from './folder/folder.component';
import { InfoPanelComponent } from './info-panel/info-panel.component';
import { LevelCheckboxComponent } from './level-checkbox/level-checkbox.component';
import { LevelIndicatorComponent } from './level-indicator/level-indicator.component';
import {
  ListItemActionsDirective,
  ListItemCaptionDirective,
  ListItemContentComponent,
  ListItemContentDirective,
  ListItemIconDirective,
  ListItemTitleDirective,
} from './list-item-content/list-item-content.component';
import { ListViewComponent, ListViewItemDirective } from './list-view/list-view.component';
import { LottieTriggerDirective } from './lottie/lottie-trigger-for.directive';
import { LottieComponent } from './lottie/lottie.component';
import { ManageCollectionComponent } from './manage-collection/manage-collection.component';
import { CollectionManagerService } from './manage-collection/services/collection-manager.service';
import { COLLECTION_MANAGER_SERVICE_TOKEN } from './manage-collection/services/collection-manager.service.interface';
import { MultiAvatarComponent } from './multi-avatar/multi-avatar.component';
import { MultiCheckBoxTableComponent } from './multi-check-box-table/multi-check-box-table.component';
import { DropdownDirective } from './notification/directives/notification-dropdown.directive';
import { NotificationComponent } from './notification/notification.component';
import { PageHeaderComponent } from './page-header/page-header.component';
import { PersonBadgeComponent } from './person-badge/person-badge.component';
import { PersonInitialsPipe } from './person-badge/pipes/person-initials.pipe';
import { PersonSummaryComponent } from './person-summary-component/person-summary.component';
import { CompletedProgressIconDirective, ProgressComponent } from './progress/progress.component';
import { RankingWidgetComponent, RankingWidgetItemsDirective } from './ranking-widget/ranking-widget.component';
import { RatingComponent } from './rating/rating.component';
import {
  ResultWidgetActionsDirective,
  ResultWidgetComponent,
  ResultWidgetContentDirective,
  ResultWidgetLabelDirective,
} from './result-widget/result-widget.component';
import { ScoreLevelAttemptsComponent, ScoreLevelComponent } from './score-level';
import {
  SectionActionsDirective,
  SectionComponent,
  SectionContentDirective,
  SectionFabContainerDirective,
  SectionFooterActionsDirective,
  SectionTitleDirective,
} from './section/section.component';
import { BackLayerContentTemplateComponent } from './shell/backlayer-content.component';
import { ShellActionsDirective } from './shell/directives/shell-actions';
import { ShellBodyDirective } from './shell/directives/shell-body.directive';
import { ShellBottomDirective } from './shell/directives/shell-bottom.directive';
import { ShellLeftDirective } from './shell/directives/shell-left.directive';
import { ShellLogoDirective } from './shell/directives/shell-logo.directive';
import { ShellTopDirective } from './shell/directives/shell-top.directive';
import { ShellBodyHeaderComponent } from './shell/shell.body-header';
import { ShellComponent } from './shell/shell.component';
import { ShellToolbarComponent } from './shell/shell.toolbar.component';
import { SlideoutModalStackComponent } from './slideout-modal/slideout-modal-stack.component';
import {
  SlideoutModalBottomActionsDirective,
  SlideoutModalComponent,
  SlideoutModalHeaderActionsDirective,
  SlideoutModalHeaderContentDirective,
} from './slideout-modal/slideout-modal.component';
import { SpeedDialComponent } from './speed-dial/speed-dial.component';
import { StickerComponent, StickerImageDirective } from './sticker/sticker.component';
import {
  StrandCardActionDirective,
  StrandCardComponent,
  StrandCardContentDirective,
  StrandCardImageDirective,
  StrandCardStickersDirective,
  StrandCardTitleDirective,
} from './strand-card/strand-card.component';
import { StudentCardActionsDirective, StudentCardComponent } from './student-card/student-card.component';
import { SurveyComponent, SurveyOptionComponent, SurveyQuestionComponent } from './survey';
import { SvgBackgroundComponent } from './svg-background/svg-background.component';
import { TaskProgressBarComponent } from './task-progress-bar/task-progress-bar.component';
import { TileComponent } from './tile/tile.component';
import { TreeNavComponent } from './tree-nav/tree-nav.component';
import { EllipsisTooltipDirective } from './utils/directives/ellipsis-tooltip.directive';
import { HideDesktopDirective } from './utils/directives/hide-desktop.directive';
import { HideMobileDirective } from './utils/directives/hide-mobile.directive';
import { IsMobileDirective } from './utils/directives/is-mobile.directive';
import { ShrinkToDirective, ShrinkToTargetDirective } from './utils/directives/shrink-to.directive';
import { AdjustColorBrightnessPipe } from './utils/pipes/adjust-color-brightness/adjust-color-brightness.pipe';
import { DictionaryHasValuePipe } from './utils/pipes/dictionary/dictionary-has-value.pipe';
import { DictionarySizePipe } from './utils/pipes/dictionary/dictionary-size.pipe';
import { HumanDateTimePipe } from './utils/pipes/human-date-time/human-date-time.pipe';
import { JoinPipe } from './utils/pipes/join/join.pipe';

import { BrowserModule } from '@campus/browser';
import {
  MultiCheckBoxTrackByPipe,
  MultiCheckBoxTrackBySubLevelPipe,
} from './multi-check-box-table/multi-check-box-track-by-item.pipe';
import { TermsModalComponent } from './terms-modal/terms-modal.component';
import { FileSizePipe } from './utils/pipes/file-size/file-size.pipe';
import { RemovePrefixStringPipe } from './utils/pipes/remove-prefix-string/remove-prefix-string.pipe';
import { TimePipe } from './utils/pipes/time/time.pipe';
import { TruncateStringPipe } from './utils/pipes/truncate-string/truncate-string.pipe';
import { WizardProgressComponent } from './wizard-progress/wizard-progress.component';
import { XpCounterComponent, XpCounterIconDirective } from './xp-counter/xp-counter.component';
import { HelpModalComponent } from './help-modal';
import { InputConfirmationModalComponent } from './input-confirmation-modal/input-confirmation-modal.component';

// leave this for aot build to succeed for elements-ink
// https://angular.io/guide/aot-metadata-errors#expression-form-not-supported
// "You can use typeof and bracket notation in normal application code.
// You just can't use those features within expressions that define Angular metadata."
export interface MappingInterface {
  [key: string]: string;
}

@NgModule({
  imports: [
    OverlayModule,
    EnvironmentModule,
    MatButtonModule,
    CommonModule,
    MatSidenavModule,
    MatInputModule,
    LayoutModule,
    RouterModule,
    CdkTreeModule,
    ReactiveFormsModule,
    FormsModule,
    MatSelectModule,
    MatFormFieldModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatTooltipModule,
    MatMenuModule,
    MatRadioModule,
    MatDatepickerModule,
    MatSliderModule,
    UtilsModule,
    MatDialogModule,
    MatListModule,
    MatTabsModule,
    MatCheckboxModule,
    MatNativeDateModule,
    PortalModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatBadgeModule,
    SvgModule,
    MatChipsModule,
    LottieModule,
    MatSlideToggleModule,
    ComponentsModule,
    BrowserModule,
  ],
  declarations: [
    FilterTextInputComponent,
    ShellComponent,
    ShellLogoDirective,
    ShellTopDirective,
    ShellActionsDirective,
    ShellLeftDirective,
    ShellBodyDirective,
    ShellBottomDirective,
    AppBarComponent,
    CardComponent,
    StudentCardComponent,
    StudentCardActionsDirective,
    CardAvatarDirective,
    CardTitleDirective,
    CardImageDirective,
    CardSubTitleDirective,
    CardOverlineDirective,
    CardContentDirective,
    CardActionsDirective,
    CardSelectedIconDirective,
    CardFooterDirective,
    CardBadgeDirective,
    CardHeaderActionsDirective,
    PageHeaderComponent,
    FileExtensionComponent,
    TreeNavComponent,
    InfoPanelComponent,
    PersonBadgeComponent,
    PersonInitialsPipe,
    ContentEditableComponent,
    ContentPreviewComponent,
    ConfirmableSelectComponent,
    EditableInlineTagListComponent,
    ContentThumbnailComponent,
    TaskProgressBarComponent,
    ButtonComponent,
    ListViewComponent,
    ListViewItemDirective,
    FolderDetailsDirective,
    FolderComponent,
    AdjustColorBrightnessPipe,
    CircleDirective,
    RoundedCornersDirective,
    BorderDirective,
    DangerDirective,
    WarningDirective,
    DisabledDirective,
    BreadcrumbsComponent,
    SlideoutModalBottomActionsDirective,
    BreadcrumbsSeparatorDirective,
    BreadcrumbsTemplateComponent,
    NotificationComponent,
    DropdownMenuComponent,
    TruncateStringPipe,
    DropdownMenuItemComponent,
    HideDesktopDirective,
    HideMobileDirective,
    IsMobileDirective,
    HumanDateTimePipe,
    RemovePrefixStringPipe,
    LargeDirective,
    PrimaryDirective,
    PersonSummaryComponent,
    BannerComponent,
    DropdownDirective,
    DropAreaComponent,
    TileComponent,
    JoinPipe,
    TimePipe,
    ManageCollectionComponent,
    DictionaryHasValuePipe,
    MultiCheckBoxTableComponent,
    ShellBottomDirective,
    FileIconComponent,
    DateRangePickerComponent,
    ConfirmationModalComponent,
    InputConfirmationModalComponent,
    TermsModalComponent,
    EmptyStateComponent,
    SectionComponent,
    SvgBackgroundComponent,
    InlineDirective,
    DenseDirective,
    FlatDirective,
    FabDirective,
    IconDirective,
    SectionContentDirective,
    SectionActionsDirective,
    SectionFooterActionsDirective,
    SectionTitleDirective,
    SectionFabContainerDirective,
    ListItemContentComponent,
    ListItemIconDirective,
    ListItemTitleDirective,
    ListItemCaptionDirective,
    ListItemActionsDirective,
    ListItemContentDirective,
    BackLayerContentTemplateComponent,
    ProgressComponent,
    CompletedProgressIconDirective,
    CollapsibleContainerComponent,
    RatingComponent,

    ExpansionPanelsComponent,
    ExpansionPanelComponent,
    ExpansionPanelHeaderDirective,
    ExpansionPanelHeaderIconDirective,
    ExpansionPanelContentDirective,
    WizardProgressComponent,
    ResultWidgetComponent,
    ResultWidgetActionsDirective,
    ResultWidgetContentDirective,
    ResultWidgetLabelDirective,
    ChartElementComponent,
    BarChartComponent,
    GridDiagramComponent,
    RankingWidgetComponent,
    RankingWidgetItemsDirective,
    ActivityGraphComponent,
    DiaboloPhaseComponent,
    FileSizePipe,
    DictionarySizePipe,
    SlideoutModalStackComponent,
    SlideoutModalComponent,
    SlideoutModalHeaderActionsDirective,
    SlideoutModalHeaderContentDirective,
    MultiAvatarComponent,
    ConfirmDeleteComponent,
    ConfirmDeleteTitleDirective,
    ConfirmDeleteContentDirective,
    ConceptCardImageDirective,
    ConceptCardTitleDirective,
    ConceptCardSubtitleDirective,
    ConceptCardActionDirective,
    ConceptCardComponent,
    XpCounterComponent,
    XpCounterIconDirective,
    StrandCardActionDirective,
    StrandCardImageDirective,
    StrandCardStickersDirective,
    StrandCardTitleDirective,
    StrandCardContentDirective,
    StrandCardComponent,
    StickerComponent,
    StickerImageDirective,
    BottomNavigationComponent,
    StrandCardComponent,
    AbilityMeterComponent,
    ParticlesDirective,
    SurveyComponent,
    SurveyOptionComponent,
    SurveyQuestionComponent,
    LevelCheckboxComponent,
    LevelIndicatorComponent,
    SpeedDialComponent,
    CappedChipComponent,
    EllipsisTooltipDirective,
    ShrinkToDirective,
    ShrinkToTargetDirective,
    LottieComponent,
    LottieTriggerDirective,
    ScoreLevelComponent,
    ScoreLevelAttemptsComponent,
    BottomNavigationLogoDirective,
    ShellToolbarComponent,
    ShellBodyHeaderComponent,
    MultiCheckBoxTrackByPipe,
    MultiCheckBoxTrackBySubLevelPipe,
    HelpModalComponent,
  ],
  exports: [
    ShellToolbarComponent,
    ShellBodyHeaderComponent,
    FilterTextInputComponent,
    LottieComponent,
    LottieTriggerDirective,
    ShellComponent,
    ShellLogoDirective,
    ShellTopDirective,
    ShellActionsDirective,
    ShellLeftDirective,
    ShellBodyDirective,
    ShellBottomDirective,
    AppBarComponent,
    CardComponent,
    StudentCardComponent,
    StudentCardActionsDirective,
    CardAvatarDirective,
    CardTitleDirective,
    CardImageDirective,
    CardSubTitleDirective,
    CardOverlineDirective,
    CardContentDirective,
    CardActionsDirective,
    CardSelectedIconDirective,
    CardFooterDirective,
    CardBadgeDirective,
    CardHeaderActionsDirective,
    PageHeaderComponent,
    InfoPanelComponent,
    ContentEditableComponent,
    ContentPreviewComponent,
    ConfirmableSelectComponent,
    EditableInlineTagListComponent,
    FileExtensionComponent,
    ContentThumbnailComponent,
    ButtonComponent,
    ListViewComponent,
    ListViewItemDirective,
    PersonBadgeComponent,
    FolderDetailsDirective,
    FolderComponent,
    SvgBackgroundComponent,
    AdjustColorBrightnessPipe,
    CircleDirective,
    RoundedCornersDirective,
    BorderDirective,
    DangerDirective,
    WarningDirective,
    DisabledDirective,
    TreeNavComponent,
    BreadcrumbsComponent,
    BreadcrumbsSeparatorDirective,
    BreadcrumbsTemplateComponent,
    NotificationComponent,
    DropdownMenuComponent,
    TruncateStringPipe,
    DropdownMenuItemComponent,
    HideDesktopDirective,
    HideMobileDirective,
    IsMobileDirective,
    HumanDateTimePipe,
    RemovePrefixStringPipe,
    LargeDirective,
    PrimaryDirective,
    PersonSummaryComponent,
    BannerComponent,
    DropdownDirective,
    DropAreaComponent,
    TileComponent,
    JoinPipe,
    DictionaryHasValuePipe,
    DictionarySizePipe,
    FileSizePipe,
    TimePipe,
    MatListModule,
    MatTabsModule,
    MatTooltipModule,
    MultiCheckBoxTableComponent,
    MatCheckboxModule,
    FileIconComponent,
    DateRangePickerComponent,
    ConfirmationModalComponent,
    InputConfirmationModalComponent,
    TermsModalComponent,
    EmptyStateComponent,
    StickerImageDirective,
    StickerComponent,
    SectionComponent,
    InlineDirective,
    DenseDirective,
    FlatDirective,
    FabDirective,
    IconDirective,
    SectionContentDirective,
    SectionActionsDirective,
    SectionFooterActionsDirective,
    SectionTitleDirective,
    SectionFabContainerDirective,
    ListItemContentComponent,
    ListItemIconDirective,
    ListItemTitleDirective,
    ListItemCaptionDirective,
    ListItemActionsDirective,
    ListItemContentDirective,
    BackLayerContentTemplateComponent,
    ProgressComponent,
    CompletedProgressIconDirective,
    CollapsibleContainerComponent,
    RatingComponent,
    ExpansionPanelsComponent,
    ExpansionPanelComponent,
    ExpansionPanelHeaderDirective,
    ExpansionPanelHeaderIconDirective,
    ExpansionPanelContentDirective,
    WizardProgressComponent,
    ResultWidgetComponent,
    ResultWidgetActionsDirective,
    ResultWidgetContentDirective,
    ResultWidgetLabelDirective,
    ChartElementComponent,
    BarChartComponent,
    GridDiagramComponent,
    RankingWidgetComponent,
    RankingWidgetItemsDirective,
    ActivityGraphComponent,
    GridDiagramComponent,
    DiaboloPhaseComponent,
    SlideoutModalStackComponent,
    SlideoutModalComponent,
    SlideoutModalHeaderActionsDirective,
    SlideoutModalHeaderContentDirective,
    MultiAvatarComponent,
    ConfirmDeleteComponent,
    ConfirmDeleteTitleDirective,
    ConfirmDeleteContentDirective,
    MatFormFieldModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatInputModule,
    ConceptCardImageDirective,
    ConceptCardTitleDirective,
    ConceptCardSubtitleDirective,
    ConceptCardActionDirective,
    ConceptCardComponent,
    XpCounterComponent,
    XpCounterIconDirective,
    StrandCardComponent,
    SvgModule,
    StickerComponent,
    StickerImageDirective,
    BottomNavigationComponent,
    StrandCardComponent,
    StrandCardActionDirective,
    StrandCardImageDirective,
    StrandCardStickersDirective,
    StrandCardTitleDirective,
    StrandCardContentDirective,
    ParticlesDirective,
    SurveyComponent,
    AbilityMeterComponent,
    LevelCheckboxComponent,
    LevelIndicatorComponent,
    SpeedDialComponent,
    CappedChipComponent,
    TaskProgressBarComponent,
    SlideoutModalBottomActionsDirective,
    EllipsisTooltipDirective,
    ShrinkToDirective,
    ShrinkToTargetDirective,
    ScoreLevelComponent,
    ScoreLevelAttemptsComponent,
    BottomNavigationLogoDirective,
    HelpModalComponent,
  ],
  providers: [
    {
      provide: COLLECTION_MANAGER_SERVICE_TOKEN,
      useClass: CollectionManagerService,
    },
    { provide: DateAdapter, useClass: BeDateAdapter },
    ManageCollectionComponent,
  ],
})
export class UiModule {}
