import { Component, HostBinding, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ChipCoreComponent } from './../chip-core.component';

@Component({
  selector: 'campus-input-chip',
  templateUrl: './input-chip.component.html',
})
export class InputChipComponent extends ChipCoreComponent implements OnChanges {
  @HostBinding('class')
  defaultClasses = ['input-chip', ...this.coreClasses];

  @Input() href: string;
  @Input() target: '_blank' | '_parent' | '_self' | '_top' | '' = '';

  @HostBinding('attr.data-remove-only')
  @Input()
  removeOnly: boolean; //TODO: removeOnly is needed for focus with keyboard navigation (WCAG)

  @HostBinding('attr.data-has-avatar') hasAvatar: boolean;

  @HostBinding('class.cursor-pointer')
  hasCursorPointer: boolean;

  @HostBinding('class.state-layer-secondary')
  hasStateLayer: boolean;

  ariaLabelRemove = 'Verwijder ' + this.ariaLabel || this.label;

  private _hasAvatar: boolean;

  protected onContentChange(changes: MutationRecord[]): void {
    super.onContentChange(changes);

    const avatarTargets = this._getAddedNodesWithAttribute(changes, 'chip-avatar');

    this.hasAvatar = this._hasAvatar || !!avatarTargets.length || undefined;
    this._hasAvatar = this.hasAvatar;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.disabled || changes.removeOnly || changes.href) {
      this.hasCursorPointer = !this.disabled && !this.removeOnly;
      this.hasStateLayer = !this.disabled;
    }
  }
}
