import { Component, EventEmitter, Input, Output } from '@angular/core';
import { LoginProviderType } from '@campus/public-portal';

@Component({
  selector: 'polpo-login-methods',
  templateUrl: './login-methods.component.html',
  styleUrls: ['./login-methods.component.scss'],
})
export class LoginMethodsComponent {
  @Input() error: string = null;
  @Input() provider: LoginProviderType = null;

  @Output() login = new EventEmitter<LoginProviderType>();
  @Output() whenCancelled = new EventEmitter<void>();

  public loginWith(provider: LoginProviderType) {
    this.login.emit(provider);
  }

  public loginWithSmartschool() {
    this.loginWith('smartschool');
  }

  public loginWithAcm() {
    this.loginWith('acm');
  }

  public loginWithLeerId() {
    this.loginWith('leerId');
  }

  public loginWithGoogle() {
    this.loginWith('google');
  }

  public loginWithMicrosoft() {
    this.loginWith('microsoft');
  }

  public loginWithFacebook() {
    this.loginWith('facebook');
  }

  public cancelLogin() {
    this.whenCancelled.emit();
  }
}
