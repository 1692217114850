import { ObserversModule } from '@angular/cdk/observers';
import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { SvgModule } from '@campus/svg';
import { UtilsModule } from '@campus/utils';
import { AdaptiveModuleConceptsDirective } from './adaptive-module/adaptive-module-concepts.directive';
import { AdaptiveModuleComponent } from './adaptive-module/adaptive-module.component';
import {
  AdaptiveTocCardComponent,
  AdaptiveTocCardContentDirective,
  AdaptiveTocCardHintDirective,
} from './adaptive-toc-card';
import { BadgeComponent } from './badge/badge.component';
import {
  ElevatedButtonComponent,
  FilledButtonComponent,
  InlineButtonComponent,
  InlineIconButtonComponent,
  OutlineButtonComponent,
  OutlineIconButtonComponent,
  SegmentedDropdownButtonComponent,
  TonalButtonComponent,
} from './button';
import { ButtonIconDirective, ButtonSuffixIconDirective } from './button/button-core/button-icon.directive';
import { ConceptButtonIconDirective } from './button/concept-button/concept-button-icon.directive';
import { ConceptButtonKaiDirective } from './button/concept-button/concept-button-kai.directive';
import { ConceptButtonLabelDirective } from './button/concept-button/concept-button-label.directive';
import { ConceptButtonComponent } from './button/concept-button/concept-button.component';
import { FabButtonComponent } from './button/fab-button/fab-button.component';
import { TimerButtonComponent } from './button/timer-button/timer-button.component';

import { DragDropModule } from '@angular/cdk/drag-drop';
import { CdkTreeModule } from '@angular/cdk/tree';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { RouterModule } from '@angular/router';
import { AppShellComponent } from './app-shell';
import { KaiRecommendBubbleComponent } from './bubble/kai-recommend/kai-recommend-bubble.component';
import { ToggleIconButtonComponent } from './button/toggle-icon-button/toggle-icon-button.component';
import { CameraComponent } from './camera/camera.component';
import {
  CardActionAreaDirective,
  CardAvatarHeadDirective,
  CardHeaderActionAreaDirective,
  CardHeadlineDirective,
  CardHeroDirective,
  CardHintDirective,
  CardMediaDirective,
  CardProgressComponent,
  CardSubheadDirective,
  CardTextDirective,
  ElevatedCardComponent,
  FilledCardComponent,
  OutlineCardComponent,
} from './card';
import { CardCoreComponent } from './card/card-core/card-core.component';
import { CardOverlineheadDirective } from './card/card-core/card-overline-head.directive';
import { CheckboxListComponent } from './checkbox-list/checkbox-list.component';
import { CheckboxComponent } from './checkbox/checkbox.component';
import {
  ChipAvatarDirective,
  ChipGroupComponent,
  ChipsInputComponent,
  FilterChipComponent,
  InputChipComponent,
  SuggestionChipComponent,
} from './chips';
import { CollapsibleSheetComponent } from './collapsible-sheet';
import { RewardDirective, SpeechBubbleComponent } from './feedback';
import { FeedbackHostDirective } from './feedback/feedback-host.directive';
import { ClaimRewardComponent } from './feedback/reward/claim-reward.component';
import { FocusRingComponent } from './focus-ring/focus-ring.component';
import { FroalaViewerComponent } from './froala-viewer';
import { icons } from './icons';
import { LevelComponent } from './levels/level.component';
import { LoadingComponent } from './loading/loading.component';
import { MediaPlayerComponent } from './media-player/media-player.component';
import { MenuItemComponent } from './menu/menu-item/menu-item.component';
import { MenuComponent } from './menu/menu.component';
import { NavigationDrawerComponent, NavigationDrawerItemComponent } from './navigation';
import { NavigationRailItemComponent } from './navigation/navigation-rail/navigation-rail-item.component';
import { NavigationRailComponent } from './navigation/navigation-rail/navigation-rail.component';
import { NavigationTreeItemComponent } from './navigation/navigation-tree/navigation-tree-item.component';
import { NavigationTreeComponent } from './navigation/navigation-tree/navigation-tree.component';
import { PercentageMeterComponent } from './percentage-meter';
import { ProductListComponent } from './product-list/product-list.component';
import { ProductComponent } from './product-list/product.component';
import {
  AbilityArcComponent,
  AbilityProgressComponent,
  LinearProgressBarComponent,
  SegmentedProgressBarComponent,
} from './progress';
import { StatusProgressComponent } from './progress/status-progress/status-progress.component';
import { OptionGroupComponent } from './select/option-group/option-group.component';
import { SelectOptionComponent } from './select/select-option/select-option.component';
import { SelectComponent } from './select/select.component';
import { SelectorWheelComponent } from './selector-wheel/selector-wheel.component';
import { StateLayerComponent } from './state/state-layer.component';
import { SwitchComponent } from './switch/switch.component';
import { TableRowComponent } from './table/row/row.component';
import { TableComponent } from './table/table.component';
import { TextFieldComponent } from './text-field/text-field.component';
import { TocHeaderHeadlineDirective } from './toc-header/toc-header-headline.directive';
import { TocHeaderImageComponent } from './toc-header/toc-header-image.component';
import { TocHeaderOverlineDirective } from './toc-header/toc-header-overline.directive';
import { TocHeaderSubheadDirective } from './toc-header/toc-header-subhead.directive';
import { TocHeaderComponent } from './toc-header/toc-header.component';
import { PlainTooltipComponent } from './tooltip/plain-tooltip.component';
import { RichTooltipComponent } from './tooltip/rich-tooltip.components';
import { TooltipService } from './tooltip/tooltip.service';
import { TouchTargetComponent } from './touch/touch-target.component';
import { VerticalSplitBottomDirective } from './vertical-split/vertical-split-top.bottom';
import { VerticalSplitTopDirective } from './vertical-split/vertical-split-top.directive';
import { VerticalSplitComponent } from './vertical-split/vertical-split.component';
import { VideoComponent } from './video/video.component';

@NgModule({
  providers: [TooltipService],
  imports: [
    CommonModule,
    UtilsModule,
    FormsModule,
    ReactiveFormsModule,
    ObserversModule,
    SvgModule,
    MatMenuModule,
    SvgModule.forRoot({
      iconMapping: icons,
    }),
    RouterModule,
    CdkTreeModule,
    DragDropModule,
    MatAutocompleteModule,
  ],
  declarations: [
    FroalaViewerComponent,
    AdaptiveTocCardComponent,
    AdaptiveTocCardContentDirective,
    AdaptiveTocCardHintDirective,
    ElevatedCardComponent,
    FilledCardComponent,
    OutlineCardComponent,
    CardCoreComponent,
    CardHeadlineDirective,
    CardSubheadDirective,
    CardHeroDirective,
    CardTextDirective,
    CardMediaDirective,
    CardActionAreaDirective,
    CardHintDirective,
    CardHeaderActionAreaDirective,
    CardProgressComponent,
    CardAvatarHeadDirective,
    InlineButtonComponent,
    OutlineButtonComponent,
    ToggleIconButtonComponent,
    ElevatedButtonComponent,
    FilledButtonComponent,
    TonalButtonComponent,
    TimerButtonComponent,
    InlineIconButtonComponent,
    OutlineIconButtonComponent,
    SegmentedDropdownButtonComponent,
    FabButtonComponent,
    ButtonSuffixIconDirective,
    SegmentedProgressBarComponent,
    CardOverlineheadDirective,
    SelectorWheelComponent,
    LinearProgressBarComponent,
    AbilityProgressComponent,
    AbilityArcComponent,
    ConceptButtonComponent,
    ConceptButtonLabelDirective,
    ConceptButtonKaiDirective,
    ConceptButtonIconDirective,
    TocHeaderComponent,
    TocHeaderHeadlineDirective,
    TocHeaderSubheadDirective,
    TocHeaderImageComponent,
    TocHeaderOverlineDirective,
    AdaptiveModuleComponent,
    AdaptiveModuleConceptsDirective,
    SpeechBubbleComponent,
    StatusProgressComponent,
    ClaimRewardComponent,
    RewardDirective,
    FeedbackHostDirective,
    ClaimRewardComponent,
    RewardDirective,
    FeedbackHostDirective,
    StatusProgressComponent,
    PercentageMeterComponent,
    ButtonIconDirective,
    ProductListComponent,
    ProductComponent,
    LevelComponent,
    CollapsibleSheetComponent,
    SuggestionChipComponent,
    InputChipComponent,
    FilterChipComponent,
    ChipGroupComponent,
    ChipAvatarDirective,
    ChipsInputComponent,
    BadgeComponent,
    VideoComponent,
    CameraComponent,
    SwitchComponent,
    StateLayerComponent,
    TouchTargetComponent,
    TextFieldComponent,
    NavigationRailComponent,
    NavigationRailItemComponent,
    NavigationDrawerComponent,
    NavigationDrawerItemComponent,
    SelectComponent,
    SelectOptionComponent,
    OptionGroupComponent,
    MenuComponent,
    MenuItemComponent,
    FocusRingComponent,
    TableComponent,
    TableRowComponent,
    CheckboxComponent,
    PlainTooltipComponent,
    RichTooltipComponent,
    CheckboxListComponent,
    MediaPlayerComponent,
    NavigationTreeComponent,
    NavigationTreeItemComponent,
    VerticalSplitComponent,
    VerticalSplitTopDirective,
    VerticalSplitBottomDirective,
    LoadingComponent,
    AppShellComponent,
    KaiRecommendBubbleComponent,
  ],
  exports: [
    FroalaViewerComponent,
    ButtonIconDirective,
    ButtonSuffixIconDirective,
    AdaptiveTocCardContentDirective,
    FilledCardComponent,
    CardHeadlineDirective,
    CardSubheadDirective,
    CardHeroDirective,
    CardTextDirective,
    CardMediaDirective,
    CardActionAreaDirective,
    CardHintDirective,
    CardHeaderActionAreaDirective,
    CardProgressComponent,
    CardAvatarHeadDirective,
    InlineButtonComponent,
    OutlineButtonComponent,
    ToggleIconButtonComponent,
    ElevatedButtonComponent,
    FilledButtonComponent,
    TonalButtonComponent,
    TimerButtonComponent,
    InlineIconButtonComponent,
    OutlineIconButtonComponent,
    FabButtonComponent,
    SegmentedDropdownButtonComponent,
    SegmentedProgressBarComponent,
    CardOverlineheadDirective,
    SelectorWheelComponent,
    LinearProgressBarComponent,
    AbilityProgressComponent,
    ConceptButtonComponent,
    ConceptButtonLabelDirective,
    ConceptButtonIconDirective,
    ConceptButtonKaiDirective,
    TocHeaderComponent,
    TocHeaderHeadlineDirective,
    TocHeaderSubheadDirective,
    TocHeaderImageComponent,
    TocHeaderOverlineDirective,
    AdaptiveModuleComponent,
    AdaptiveModuleConceptsDirective,
    SpeechBubbleComponent,
    StatusProgressComponent,
    ClaimRewardComponent,
    RewardDirective,
    FeedbackHostDirective,
    PercentageMeterComponent,
    ElevatedCardComponent,
    ClaimRewardComponent,
    RewardDirective,
    FeedbackHostDirective,
    StatusProgressComponent,
    PercentageMeterComponent,
    AdaptiveTocCardComponent,
    AdaptiveTocCardHintDirective,
    ElevatedCardComponent,
    OutlineCardComponent,
    ProductListComponent,
    ProductComponent,
    LevelComponent,
    CollapsibleSheetComponent,
    SuggestionChipComponent,
    InputChipComponent,
    FilterChipComponent,
    ChipGroupComponent,
    ChipAvatarDirective,
    ChipsInputComponent,
    BadgeComponent,
    VideoComponent,
    CameraComponent,
    SwitchComponent,
    StateLayerComponent,
    TouchTargetComponent,
    TextFieldComponent,
    NavigationRailComponent,
    NavigationRailItemComponent,
    NavigationDrawerComponent,
    NavigationDrawerItemComponent,
    NavigationTreeComponent,
    NavigationTreeItemComponent,
    SelectComponent,
    SelectOptionComponent,
    OptionGroupComponent,
    MenuComponent,
    MenuItemComponent,
    FocusRingComponent,
    TableComponent,
    TableRowComponent,
    CheckboxComponent,
    PlainTooltipComponent,
    RichTooltipComponent,
    CheckboxListComponent,
    MediaPlayerComponent,
    VerticalSplitComponent,
    VerticalSplitTopDirective,
    VerticalSplitBottomDirective,
    LoadingComponent,
    AppShellComponent,
    KaiRecommendBubbleComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ComponentsModule {}
