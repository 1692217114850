<div class="[ cluster cluster-space-2xs ]">
  <button
    type="button"
    class="[ action flex-nowrap items-center ] [ cursor-pointer ]"
    [attr.aria-label]="ariaLabel"
    [attr.aria-selected]="selected || undefined"
    [disabled]="disabled || undefined"
    role="option"
    tabindex="-1"
  >
    <campus-icon svgIcon="check" class="[ icon-size-s ]" aria-hidden="true" *ngIf="selected"></campus-icon>
    <campus-icon
      [svgIcon]="leadingIcon"
      class="[ icon-size-s ]"
      aria-hidden="true"
      *ngIf="!selected && leadingIcon"
    ></campus-icon>

    <span class="[ label-large truncate ]">{{ label }}</span>

    <button
      *ngIf="removable"
      class="[ action ] [ icon-size-s ] [ on-surface-variant hover:on-surface ] [ cursor-pointer ] [ transition-colors ]"
      [attr.aria-label]="ariaLabelRemove"
      [disabled]="disabled"
      tabindex="-1"
      (click)="handleRemoveClick()"
      data-cy="btn-chip-remove"
    >
      <campus-icon svgIcon="close" class="[ icon-size-s ]" aria-hidden="true"></campus-icon>
    </button>
  </button>
</div>

<ng-template #content> </ng-template>

<campus-state-layer
  [for]="element"
  [ngClass]="selected ? 'bg-on-secondary-container' : 'bg-on-surface-variant'"
></campus-state-layer>

<campus-focus-ring [for]="element"></campus-focus-ring>
