import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CampaignApi, SchoolApi } from '../api';
import { TeacherInterface } from '../api/interfaces/Teacher.interface';

@Injectable({
  providedIn: 'root',
})
export class LinkedPersonService {
  constructor(private schoolApi: SchoolApi, private campaignApi: CampaignApi) {}

  createTeacherForCampaign(
    campaign: string,
    teacher: Partial<TeacherInterface> & Required<Pick<TeacherInterface, 'firstName' | 'name' | 'email' | 'password'>>
  ): Observable<string> {
    return this.campaignApi.createTeacher(campaign, teacher) as Observable<string>;
  }
}
