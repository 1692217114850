import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  inject,
  Input,
  Output,
} from '@angular/core';
import { InputBooleanAttribute, isTruthyInput } from '../../core/empty-attribute';
import { HighlightableOption } from '../../core/navigation/activedescendant-list.controller';
import { NavigableOption } from '../../core/navigation/navigable-list.controller';
import { isSelectableKey } from '../../core/selection';
import { SelectableOption } from '../../core/selection/selectable-list.controller';
import { TypeaheadItem } from '../../core/typeahead';
import { CDS_MENU_ITEM_PARENT, MenuComponent } from '../menu.component';

let _uniqueIdCounter = 0;

export interface MenuItemInterface<T = any>
  extends HighlightableOption,
    NavigableOption,
    SelectableOption,
    TypeaheadItem {
  label: string;
  selectionChange: EventEmitter<MenuItemSelectionChange>;
  value: T;
}

export class MenuItemSelectionChange<T = any> {
  constructor(public source: MenuItemComponent<T>, public isUserInput = false) {}
}

@Component({
  selector: 'campus-menu-item',
  templateUrl: './menu-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: CDS_MENU_ITEM_PARENT, useExisting: MenuComponent }],
})
export class MenuItemComponent<T = any> implements MenuItemInterface<T> {
  @HostBinding('class')
  defaultClasses = [
    'menu-item',
    'relative',

    'flex',
    'gap-xs',
    'items-center',

    'surface-container-lowest',

    'p-b-xs',

    'cursor-pointer',
    'outline-none',

    'selected:surface-container-highest',
  ];

  private _cd = inject(ChangeDetectorRef);
  protected _parent = inject(CDS_MENU_ITEM_PARENT);
  element = inject(ElementRef).nativeElement;

  private _active = false;
  private _selected = false;
  private _disabled = false;

  @Input() id = `cds-menu-item-${_uniqueIdCounter++}`;
  @Input() label: string;
  @Input() overline: string;
  @Input() supportingText: string;
  @Input() trailingSupportingText: string;

  @HostBinding('attr.data-has-leading-icon')
  @Input()
  leadingIcon: string;
  @HostBinding('attr.data-has-trailing-icon')
  @Input()
  trailingIcon: string;

  @Input()
  checkbox: InputBooleanAttribute;
  @Input() indeterminate: boolean;

  @Input() value: T;

  @HostBinding('attr.aria-disabled')
  @HostBinding('attr.disabled')
  @HostBinding('class.pointer-event-none')
  @HostBinding('class.cursor-default')
  @HostBinding('class.opacity-disabled')
  @Input()
  get disabled() {
    return this._disabled === true ? true : undefined;
  }
  set disabled(value: InputBooleanAttribute) {
    this._disabled = isTruthyInput(value);
  }

  @HostBinding('attr.tabindex')
  @Input()
  tabIndex = -1;

  get multiple(): boolean {
    return isTruthyInput(this._parent.multiple);
  }

  @HostBinding('attr.data-has-leading-icon')
  get hasCheckbox(): boolean {
    return isTruthyInput(this._parent.checkbox) || isTruthyInput(this.checkbox); //
  }

  @HostBinding('class.active')
  get active(): boolean {
    return this._active;
  }

  @HostBinding('attr.aria-selected')
  @HostBinding('class.selected')
  @Input()
  get selected(): boolean {
    return this._selected;
  }
  set selected(value: InputBooleanAttribute) {
    this._selected = isTruthyInput(value);
  }

  @HostBinding('attr.role')
  @Input()
  role = 'menuitem';

  @Output() selectionChange = new EventEmitter<MenuItemSelectionChange<T>>();

  @HostListener('click', ['$event'])
  onClick(event: MouseEvent): void {
    this.selectViaInteraction();
  }

  @HostListener('keydown', ['$event'])
  onKeydown(event: KeyboardEvent): void {
    if (isSelectableKey(event.code)) {
      this.selectViaInteraction();
      event.preventDefault();
    }
  }

  get typeaheadText() {
    return this.label?.toLowerCase() || this.supportingText?.toLowerCase() || this.overline?.toLowerCase() || '';
  }

  focus() {
    this.element.focus();
  }

  select(emitEvent = true): void {
    if (!this._selected) {
      this._selected = true;
      this._cd.markForCheck();

      if (emitEvent) {
        this._emitSelectionChangeEvent();
      }
    }
  }

  deselect(emitEvent = true): void {
    if (this._selected) {
      this._selected = false;
      this._cd.markForCheck();

      if (emitEvent) {
        this._emitSelectionChangeEvent();
      }
    }
  }

  selectViaInteraction(): void {
    if (!this.disabled) {
      this._selected = this.multiple ? !this._selected : true;
      this._cd.markForCheck();
      this._emitSelectionChangeEvent(true);
    }
  }

  setActive(): void {
    if (!this._active) {
      this._active = true;
      this.tabIndex = 0;
      this.focus();
      this._cd.markForCheck();
    }
  }
  setInactive(): void {
    if (this._active) {
      this._active = false;
      this.tabIndex = -1;
      this._cd.markForCheck();
    }
  }

  private _emitSelectionChangeEvent(isUserInput = false): void {
    this.selectionChange.emit(new MenuItemSelectionChange<T>(this, isUserInput));
  }
}
