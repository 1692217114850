import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { ButtonCoreComponent } from '../button-core/button-core.component';

@Component({
  selector: 'campus-segmented-dropdown-button, [segmented-dropdown-button]',
  templateUrl: './segmented-dropdown-button.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SegmentedDropdownButtonComponent extends ButtonCoreComponent {
  @HostBinding('class')
  defaultClasses = [
    ...this.coreClasses,
    'segmented-dropdown-button',

    'flex',
    'transparent',
    'text-opacity-medium',

    'p-b-3xs',
    'p-i-0',

    'corner-full',
    'border',
    'border-transparent',

    'hover:text-primary',
    'hover:text-opacity-high',

    'selected:ring',
    'selected:ring-primary',
    'selected:ring-2',
    'disabled:opacity-low',
  ];

  @Input() icon = 'cds-comp:arrow-drop-down';
}
