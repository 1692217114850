/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prefer-rest-params */
import { Injectable } from '@angular/core';
import { ObjectFunctions } from '@campus/utils';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CampaignInterface, PersonInterface } from '../interfaces';
import { ModelApiBase } from '../model-api.base';
import { PolpoApi } from '../polpo-api';

@Injectable({ providedIn: 'root' })
export class CampaignApi extends ModelApiBase {
  constructor(api: PolpoApi) {
    super(api, 'Campaigns');
  }

  findById(key: string): Observable<CampaignInterface> {
    const url = `/getCampaign/${key}`;

    const asDate = ObjectFunctions.castAs(Date);

    return this.get(url).pipe(map(asDate(['start', 'end'])));
  }

  createTeacher(campaign: string, teacher: Partial<PersonInterface>) {
    const path = `${campaign}/teachers`;

    return this.post<string>(path, undefined, teacher);
  }
}
