
<campus-maintenance-mode *ngIf="isMaintenanceMode$ | async; else operationalMode" [statusPageUrl]="statusPageUrl"></campus-maintenance-mode>

<ng-template #operationalMode>
  <ng-container *ngIf="role$ | async as role">
    <ng-container [ngSwitch]="role">
      <!-- Student registration form -->
      <div *ngSwitchCase="'student'" class="[ container container-max-width-4xl stack ]">
        <h1 class="[ headline-large text-center ]" data-cy="form-header">Registreer als leerling</h1>



        <div class="[ cluster ]">
          <div class="[ items-start justify-center ]">
            <campus-svg svgImage="contact-form" alt="Login Formulier" class="[ max-w-2xs ]" />
            <div class="[ stack flex-1 max-w-full tablet-p:min-w-m ]">
              <div class="[ p-b-m ]">
                <polpo-register-form
                  (register)="register($event)"
                  [error]="registrationError$ | async"
                />
              </div>
            </div>
          </div>
        </div>

      </div>

      <!-- Teacher registration form -->
      <div *ngSwitchCase="'teacher'" class="[ container container-max-width-4xl stack ]">
        <ng-container *ngIf="campaign$ | async as campaign">
          <h1 class="[ headline-large text-center ]" data-cy="form-header">Registreer als leerkracht</h1>
          <ng-container *ngIf="campaign.key; else noCampaign">
            <h2 class="[ headline-large text-center container-secondary text-secondary ]">
              {{ campaign.title }}
            </h2>
            <div class="[ cluster ]">
              <div class="[ items-start justify-center ]">
                <campus-svg svgImage="contact-form" alt="Login Formulier" class="[ max-w-2xs ]" />
                <div class="[ stack flex-1 max-w-full tablet-p:min-w-m ]">
                  <div class="[ p-b-m ]">
                    <div class="[ error-container p-xs m-s ]" *ngIf="campaign.isExpired">
                      <div class="[ text-center ]">
                        <p class="[ text-error ]">Deze campagne is afgelopen.</p>
                      </div>
                    </div>
                    <polpo-register-teacher-form
                      *ngIf="!campaign.isExpired"
                      (register)="registerTeacher($event, campaign.key)"
                      [error]="registrationError$ | async"
                    />
                  </div>
                </div>
              </div>
            </div>
          </ng-container>

          <ng-template #noCampaign>
            <h2 class="[ headline-large text-center container-secondary text-secondary ]">
              Contacteer uw schoolbeheerder voor uw logingegevens van Polpo.
            </h2>
          </ng-template>
        </ng-container>




      </div>
    </ng-container>
  </ng-container>
</ng-template>
