<form [formGroup]="registerForm" (ngSubmit)="onSubmit()" class="[ stack ]" data-cy="register-form">
  <mat-form-field>
    <mat-label data-cy="label-register-form-first-name">Voornaam</mat-label>
    <input matInput type="text" formControlName="firstName" data-cy="input-register-form-first-name" />
  </mat-form-field>
  <mat-form-field>
    <mat-label data-cy="label-register-form-last-name">Naam</mat-label>
    <input matInput type="text" formControlName="lastName" data-cy="input-register-form-last-name" />
  </mat-form-field>
  <mat-form-field>
    <mat-label data-cy="label-register-form-email">E-mailadres</mat-label>
    <input matInput type="email" formControlName="email" data-cy="input-register-form-email" />
  </mat-form-field>
  <mat-form-field>
    <mat-label data-cy="label-register-form-password">Wachtwoord</mat-label>
    <input matInput [type]="pwdInputType" formControlName="password" data-cy="input-register-form-password" />
    <mat-error *ngIf="registerForm.get('password').errors?.['minlength']" data-cy="register-form-error">
      Het wachtwoord moet minimaal {{ registerForm.get('password').errors['minlength'].requiredLength }} karakters
      bevatten.
    </mat-error>
    <mat-error *ngIf="registerForm.get('password').errors?.['maxlength']" data-cy="register-form-error">
      Het wachtwoord mag maximaal {{ registerForm.get('password').errors['maxlength'].requiredLength }} karakters
      bevatten.
    </mat-error>
  </mat-form-field>
  <mat-form-field>
    <mat-label data-cy="label-register-form-repeat-password">Herhaal wachtwoord</mat-label>
    <input
      matInput
      [type]="pwdInputType"
      formControlName="passwordCheck"
      data-cy="input-register-form-repeat-password"
    />
    <mat-error
      *ngIf="
      registerForm.errors?.['passwordMatch'] && (registerForm.get('passwordCheck').touched)"
      class=""
      data-cy="register-form-error"
    >
      Het wachtwoord komt niet overeen.
    </mat-error>
  </mat-form-field>
  <div
    class="[ corner-m p-i-m p-b-s error-container border border-error body-large ]"
    *ngIf="error"
    data-cy="register-form-error"
  >
    Het registreren is mislukt. Probeer later opnieuw of contacteer de helpdesk.
    <a [href]="portalEnvironment.ui.contact.helpdesk.link">{{ portalEnvironment.ui.contact.helpdesk.label }}</a>
  </div>
  <div class="[ cluster ]">
    <div class="[ justify-between ]">
      <button
        filled-button
        type="submit"
        [disabled]="!registerForm.valid"
        leadingIcon="login"
        data-cy="btn-register-form-submit"
      >
        Registreren
      </button>
    </div>
  </div>
</form>
