<ng-content select="campus-icon, [button-icon]"></ng-content>
<campus-icon *ngIf="icon" [svgIcon]="icon"></campus-icon>
<campus-state-layer [for]="element" type="circular" class="bg-primary"></campus-state-layer>
<campus-focus-ring [for]="element"></campus-focus-ring>

<dcr-badge *ngIf="badge" [count]="badge" class="[ absolute -top-2xs -right-2xs ]"></dcr-badge>

<campus-tooltip
  *ngIf="plainTooltip"
  [plainTooltip]="plainTooltip"
  [tooltipDelay]="250"
  [anchor]="element"
  class="[ visually-hidden ]"
>
</campus-tooltip>
