import { Component, HostBinding, inject, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { ENVIRONMENT_WEBSITE_TOKEN } from '@campus/environment';
import { CampaignInterface, RegistrationCredentials } from '@campus/public-portal';
import { from, Observable } from 'rxjs';
import { catchError, filter, map, shareReplay, switchMap } from 'rxjs/operators';
import { RedirectService } from '../../services/redirect.service';
import { RegisterTeacherInterface } from './components/register-teacher-form/register-teacher-form.component';
import { RegisterPageContainerViewModel } from './register-page-container.viewmodel';

type RegistrationRole = 'teacher' | 'student';

@Component({
  selector: 'polpo-register-page-container',
  templateUrl: './register-page-container.component.html',
  providers: [RegisterPageContainerViewModel],
})
export class RegisterPageContainerComponent implements OnInit {
  @HostBinding('style.padding-top') paddingTop = '132px';
  @HostBinding('style.display') display = 'block';

  @HostBinding('class') classList = 'block p-m';

  private vm = inject(RegisterPageContainerViewModel);
  private environmentWebsite = inject(ENVIRONMENT_WEBSITE_TOKEN);
  private route = inject(ActivatedRoute);
  private redirectService = inject(RedirectService);

  public registrationError$ = this.vm.registrationError$;
  public role$: Observable<RegistrationRole>;
  public campaign$: Observable<CampaignInterface>;
  public isMaintenanceMode$: Observable<boolean>;
  public statusPageUrl: string;

  ngOnInit(): void {
    this.isMaintenanceMode$ = this.vm.isMaintenanceMode$;
    this.statusPageUrl = this.environmentWebsite.statusPageUrl;

    this.role$ = this.route.queryParams.pipe(
      map((params) => (params.role as RegistrationRole) || 'student'),
      shareReplay({ bufferSize: 1, refCount: true })
    );

    this.campaign$ = this.route.queryParams.pipe(
      filter((params) => params.campaign),
      map((params) => params.campaign),
      switchMap((campaignKey) =>
        this.vm.getCampaign(campaignKey).pipe(catchError(() => from([{} as CampaignInterface])))
      ),

      shareReplay({ bufferSize: 1, refCount: true })
    );
  }

  public register(data: RegistrationCredentials) {
    this.vm.register(data);
  }

  public registerTeacher(data: RegisterTeacherInterface, campaign: string) {
    this.vm.registerTeacher(data, campaign).subscribe((loginLink) => {
      this.redirectService.redirect(loginLink);
    });
  }
}
