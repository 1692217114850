/* eslint-disable @angular-eslint/component-class-suffix */
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DoCheck,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  inject,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  FormControlDirective,
  FormControlName,
  FormGroupDirective,
  NgControl,
  ValidationErrors,
  Validator,
  Validators,
} from '@angular/forms';
import { ObjectFunctions } from '@campus/utils';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { asEmptyAttribute, EmptyAttribute, InputBooleanAttribute, isTruthyInput } from './empty-attribute';

/**
 * Base class for all form elements
 */
@Component({ template: '', changeDetection: ChangeDetectionStrategy.OnPush })
export class FormElementCore<T> implements ControlValueAccessor, Validator, OnInit, OnChanges, DoCheck, OnDestroy {
  //#region Dependency Injection
  public elementRef = inject(ElementRef);
  protected _cd = inject(ChangeDetectorRef);
  private injector = inject(Injector);

  //#endregion

  //#region private fields
  protected _value: T;

  private _disabled: boolean;
  private _manualError$ = new BehaviorSubject<boolean>(false);

  protected destroyed$ = new Subject<void>();

  //#endregion

  //#region Outputs
  @Output() valueChange = new EventEmitter<T>();
  //#endregion

  //#region public fields
  /**
   * When set to true, the error text's `role="alert"` will be removed, then
   * re-added after an animation frame. This will re-announce an error message
   * to screen readers.
   */
  public refreshErrorAlert = false;

  public id = `${Math.random().toString(36).substring(2, 9)}`;

  public focused: boolean;
  public hovered: boolean;

  public formControlErrors$: BehaviorSubject<ValidationErrors | null> = new BehaviorSubject(null);
  public status$: Observable<'valid' | 'invalid'> = combineLatest([
    this.formControlErrors$.asObservable(),
    this._manualError$,
  ]).pipe(map(([errors, manualError]) => (manualError || (errors && this.formControl?.touched) ? 'invalid' : 'valid')));
  //#endregion

  //#region Inputs

  @HostBinding('attr.tabindex')
  @Input()
  tabindex = -1;

  @HostBinding('attr.aria-labelledby')
  @Input()
  ariaLabelledBy: string;

  @Input() ariaLabel: string;

  @HostBinding('attr.aria-label')
  ariaLabelValue: string;

  @Input() label: string;
  @Input() supportingText: string;

  @Input() formControl: FormControl;
  @Input() errorText: string | Record<string, string>;
  // only to force manual error state
  // form errors are preferred and are automatically detected
  @Input()
  set error(value: InputBooleanAttribute) {
    this._manualError$.next(isTruthyInput(value));
  }
  @Input() compareWith: (o1: any, o2: any) => boolean = ObjectFunctions.deepEquals;

  @Input()
  set value(value: T) {
    this.setValue(value, true);
  }

  get value(): T {
    return this._value;
  }

  @HostBinding('attr.aria-disabled')
  @HostBinding('attr.disabled')
  @HostBinding('class.pointer-event-none')
  @HostBinding('class.cursor-default')
  @Input()
  get disabled() {
    return this._disabled === true ? true : undefined; //removed the disabled attribute for false values
  }
  set disabled(value: InputBooleanAttribute) {
    this._disabled = isTruthyInput(value);
    this.onDisabledChanged(this._disabled);
  }

  @HostBinding('$.data-error.attr')
  @HostListener('$.data-error.attr')
  get getDataError() {
    return this.status$.pipe(map((status) => (status === 'invalid' ? '' : undefined)));
  }

  @HostBinding('attr.aria-required')
  @HostBinding('attr.required')
  @Input()
  required: InputBooleanAttribute;

  @HostBinding('attr.aria-readonly')
  @Input()
  readOnly: InputBooleanAttribute;

  @HostBinding('attr.data-focused')
  isFocused: EmptyAttribute;

  //#endregion

  //#region inner methods
  protected isDirty = false;

  onChange: (_: any) => void;
  onTouched: () => void = () => {};

  protected onDisabledChanged(value: boolean): void {}
  protected onValueChanged(shouldPropagate?: boolean): void {}

  get element() {
    return this.elementRef.nativeElement;
  }
  //#endregion

  //#region Lifecycle Hooks
  ngOnInit(): void {
    try {
      const ngControl = this.injector.get(NgControl, null, { self: true });

      if (ngControl instanceof FormControlName) {
        this.formControl = this.injector.get(FormGroupDirective).getControl(ngControl);
      } else if (ngControl instanceof FormControlDirective) {
        this.formControl = (ngControl as FormControlDirective).form as FormControl;
      }

      this.formControl?.statusChanges.pipe(takeUntil(this.destroyed$)).subscribe(() => {
        this.formControlErrors$.next(this.formControl.errors);
      });

      if (this.formControl?.hasValidator(Validators.required)) {
        this.required = true;
      }
    } catch (err) {
      // const warning = `The formControlName or ngModel input is recommended for ${this.constructor.name}.`;
      // console.warn(warning);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.ariaLabelledBy || changes.ariaLabel || changes.supportingText || changes.label) {
      this.ariaLabelValue = this._getAriaLabelValue();
    }
    if (changes.readOnly) {
      this.readOnly = isTruthyInput(changes.readOnly.currentValue);
    }
    if (changes.required) {
      this.required = isTruthyInput(changes.required.currentValue);
    }
  }

  ngDoCheck(): void {
    // The past render cycle removed the role="alert" from the error message.
    // Re-add it after an animation frame to re-announce the error.
    // Because only changing the content of an existing role="alert" is announced by screen readers,
    // @see https://www.w3.org/TR/WCAG20-TECHS/ARIA19.html for more information
    if (this.refreshErrorAlert) {
      this.refreshErrorAlert = false;
      this._cd.detectChanges();
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  //#endregion

  @HostListener('focusin')
  @HostListener('focusout')
  handleFocusChange() {
    // When calling focus() during change, it's possible
    // for blur to be called after the new focus event. Rather than set
    // `this.focused` to true/false on focus/blur, we always set it to whether
    // or not the input itself is focused.
    if (this.readOnly) return;

    this.formControl?.markAsTouched();
    this.focused = this.element.matches(':focus') ?? false;
    this.isFocused = asEmptyAttribute(this.focused);
  }

  handleBlur() {
    if (this.readOnly) return;

    this.onTouched();
  }

  //#endregion

  //#region ControlValueAccessor
  writeValue(obj: any): void {
    this.setValue(obj, false);
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState(isDisabled: boolean): void {
    this._disabled = isDisabled;
    this._cd.markForCheck();
  }

  /**
   * Re-announces the field's error supporting text to screen readers.
   *
   * Error text announces to screen readers anytime it is visible and changes.
   * Use the method to re-announce the message when the text has not changed,
   * but announcement is still needed (such as for `reportValidity()`).
   */
  reannounceError() {
    this.refreshErrorAlert = true;
  }

  //#endregion

  //#region Validator
  //Override this method in child classes to add custom validation
  validate(control: FormControl) {
    return null;
  }
  //#endregion

  //#region private methods
  public setValue(value: T, shouldPropagate: boolean) {
    this._value = value;

    if (shouldPropagate && this.onChange) {
      this.onChange(value);
      this.onTouched();
    }
    if (!this.onChange) {
      this.valueChange.emit(value);
    }
    this.onValueChanged(shouldPropagate);
  }

  private _getAriaLabelValue() {
    if (this.ariaLabelledBy) return undefined;
    if (this.ariaLabel) return this.ariaLabel;
    if (this.supportingText && this.label) return this.label;

    return undefined;
  }
  //#endregion
}
