<div class="[ cluster cluster-space-2xs ]">
  <div class="flex-nowrap" [class.opacity-low]="disabled">
    <campus-icon *ngIf="leadingIcon" [svgIcon]="leadingIcon" class="icon-current"></campus-icon>

    <span class="[ label-large truncate ]"><ng-content></ng-content></span>

    <campus-icon *ngIf="trailingIcon" [svgIcon]="trailingIcon" class="icon-current"></campus-icon>
  </div>
</div>

<dcr-badge *ngIf="badge" [count]="badge" class="[ absolute -top-3xs -right-3xs ]"></dcr-badge>

<campus-state-layer [for]="element" class="bg-on-primary"></campus-state-layer>
<campus-focus-ring [for]="element"></campus-focus-ring>
