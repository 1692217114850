<div class="[ cluster cluster-space-2xs ]">
  <a
    class="[ action flex-nowrap items-center ] [ cursor-pointer ]"
    *ngIf="href"
    [attr.aria-label]="ariaLabel"
    [href]="href"
    [target]="target"
  >
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </a>

  <span class="[ action flex-nowrap items-center ]" *ngIf="removeOnly" [attr.aria-label]="ariaLabel">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </span>

  <button
    *ngIf="!href && !removeOnly"
    type="button"
    class="[ action flex-nowrap items-center ] [ cursor-pointer ]"
    [attr.aria-label]="ariaLabel"
    [disabled]="disabled || undefined"
    tabindex="-1"
  >
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </button>

  <ng-template #content>
    <ng-content select="[chip-avatar]"></ng-content>
    <ng-content select="campus-icon, [chip-icon]"></ng-content>
    <span class="[ label-large truncate ]">{{ label }}</span>
    <button
      *ngIf="removable"
      class="[ action ] [ icon-size-s ] [ on-surface-variant hover:on-surface ] [ cursor-pointer ] [ transition-colors ]"
      [attr.aria-label]="ariaLabelRemove"
      [disabled]="(!href && disabled) || undefined"
      (click)="handleRemoveClick()"
      data-cy="btn-chip-remove"
      tabindex="-1"
    >
      <campus-icon svgIcon="close" class="[ icon-size-s ]"></campus-icon>
    </button>
    <ng-content select="[chip-suffix-icon]"></ng-content>
  </ng-template>
</div>

<campus-state-layer [for]="element" class="bg-on-surface-variant"></campus-state-layer>
<campus-focus-ring [for]="element"></campus-focus-ring>
