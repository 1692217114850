import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CampaignInterface } from '../api';
import { CampaignApi } from '../api/models/campaign-api';

@Injectable({
  providedIn: 'root',
})
export class CampaignService {
  private campaignApi = inject(CampaignApi);

  getCampaign(key: string): Observable<CampaignInterface> {
    return this.campaignApi.findById(key).pipe(
      map((campaign) => ({
        ...campaign,
        isExpired: campaign.end < new Date(),
      }))
    );
  }
}
